<template>
  <div class="member-manager">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item><i class="icon-home"></i>会员档案</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <search-form></search-form>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import searchForm from 'pages/member/member-index/search-form';

export default {
	data() {
		return {};
	},
	components: {
		'v-title': vTitle,
		'search-form': searchForm,
	},
};
</script>

<style lang="css" scoped>
</style>
