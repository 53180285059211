<template>
  <div>
    <div class="member-search">
      <Row>
        <Col span="24">
          <h2 class="member-search-title border-bottom"><span class="member-search-text">按条件搜索</span></h2>
        </Col>
      </Row>
      <div class="search-form"  @keyup.enter="handleSearch()">
        <Form :label-width="112" :model="searchData" ref="formValidate" :rules="ruleValidate">
          <Row>
            <Col span="5">
              <Form-item label="姓名：">
                <Input v-model="searchData.name" placeholder="请输入"></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="性别：">
                <Select clearable v-model="gender" placeholder="请选择" class="w80">
                  <Option value="0">男</Option>
                  <Option value="1">女</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="民族：">
                <Select clearable filterable placeholder="请选择" v-model="searchData.nation">
                  <Option v-for="item in nationList" :value="item" :key="item">{{item}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="6">
              <Form-item label="婚姻状况：">
                <Select clearable placeholder="请选择" class="w80" v-model="searchData.marita" @on-change="handleMarita">
                  <Option v-for="item in maritalType" :value="item.id" :key="item.id">{{item.name}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col span="5">
              <Form-item label="籍贯：">
                <Cascader :data="nativePlaceList" v-model="native_place" @on-change="handleNative"></Cascader>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="联系电话：">
                <Input v-model="searchData.phone" placeholder="请输入"></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="身份证号：">
                <Input v-model="searchData.id_card" placeholder="请输入"></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="6">
              <Form-item label="职业：">
                <Select clearable placeholder="请选择" v-model="searchData.job">
                  <Option v-for="item in jobType" :value="item" :key="item">{{item}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col span="5">
              <Form-item label="会员等级：">
                <Select filterable clearable placeholder="请选择" v-model="searchData.grade" @on-change="handleGender">
                  <Option v-for="item in gradeType" :value="item.grade_name" :key="item.grade_name">{{item.grade_name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="与子女同住：">
                <Select clearable placeholder="请选择" class="w80" v-model="conhabit" @on-change="handleConhabit">
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="密度：" v-if="choConhabit">
                <Cascader :data="densityGradeList" v-model="densityData" @on-change="handleChangeDensity"></Cascader>
              </Form-item>
              <Form-item label="密度：" v-else>
                <Cascader :data="densityGradeList" disabled v-model="densityData" @on-change="handleChangeDensity"></Cascader>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="6">
              <Form-item label="年龄：">
                <Row>
                  <Col :lg="{span: 9}" :md="{span: 9}">
                    <Form-item prop="s_age">
                      <Input v-model="searchData.s_age" placeholder="请输入"></Input>
                    </Form-item>
                  </Col>
                  <Col :lg="{span: 3}" :md="{span: 3}" class="tac">至</Col>
                  <Col :lg="{span: 9}" :md="{span: 9}">
                    <Form-item prop="b_age">
                      <Input v-model="searchData.b_age" placeholder="请输入"></Input>
                    </Form-item>
                  </Col>
                  <Col :lg="{span: 3}" :md="{span: 3}" class="tac">岁</Col>
                </Row>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col span="11">
              <Form-item label="顾客类型：">
                <Row>
                  <Col span="10">
                    <Select clearable v-model="searchData.member_type1" placeholder="请选择" @on-change="changeType">
                      <Option :value="v.id" v-for="v in typeList" :key="v.id">{{v.name}}</Option>
                    </Select>
                  </Col>
                  <Col span="1">&nbsp;</Col>
                  <Col span="13">
                    <Select clearable multiple  v-model="type_item_ids" placeholder="请选择" @on-change="changeTypeItem" @click.native="changeItems">
                      <Option :value="v.id" v-for="v in typeItemList" :key="v.id">{{v.name}}</Option>
                    </Select>
                  </Col>
                </Row>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="建档日期：">
                <Date-picker v-model="time" @on-change="handleChangeDate" type="daterange" placeholder="选择日期"></Date-picker>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="设备使用情况：">
                <Select clearable v-model="searchData.device_type" placeholder="请选择" @on-change="changeDevice">
                  <Option :value="v.value" v-for="v in deviceList" :key="v.value">{{ v.label }}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row v-if="!branchShow">
            <Col span="5">
              <Form-item label="省份">
                <Select clearable v-model="searchData.pid" placeholder="请选择" @on-change="changePro">
                  <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="城市">
                <Select clearable v-model="searchData.cid" placeholder="请选择" @on-change="changeCity" @click.native="changeBossCity">
                  <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="门店">
                <Select clearable v-model="searchData.sid" placeholder="请选择" @on-change="changeBranch" @click.native="changeBossBranch">
                  <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="6">
              <Form-item label="服务员工：">
                <Select clearable placeholder="请选择" v-model="searchData.staff" @click.native="handleChangeStaff">
                  <Option v-for="item in staffFormData" :value="item.id" :key="item.id">{{item.name}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <!-- 员工 -->
          <Row v-else>
            <Col span="5">
              <Form-item label="省份">
                <Input disabled v-model="proName"></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="城市">
                <Input disabled v-model="cityName"></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="门店">
                <Input disabled v-model="sName"></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="6">
              <Form-item label="服务员工：">
                <Select clearable placeholder="请选择" v-model="searchData.staff" @click.native="handleChangeStaff">
                  <Option v-for="item in staffFormData" :value="item.id" :key="item.id">{{item.name}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col span="5">
              <Form-item label="ABO属性：" prop="ABO_type">
                <Select clearable v-model="searchData.ABO_type" placeholder="请选择" @on-change="changeABO">
                  <Option :value="v.value" v-for="v in ABOList" :key="v.value">{{ v.label }}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="12">
              <Form-item label="生日：">
                <Row :gutter="16">
                  <Col span="3">
                    <Select v-model="birthday_start_month" style="width:100%" clearable @on-change="changeBirthdayStartMonth" placeholder='月'>
                      <Option v-for="item in birthday_start_monthList" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                  </Col>
                  <Col span="3">
                    <Select v-model="birthday_start_days" style="width:100%" clearable placeholder='日' @on-change="changeBirthdayStartDay">
                      <Option v-for="item in birthday_start_daysList" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                  </Col>
                  <Col span="1" class="tac">至</Col>
                  <Col span="3">
                    <Select v-model="birthday_end_month" style="width:100%" clearable @on-change="changeBirthdayEndMonth" placeholder='月'>
                      <Option v-for="item in birthday_end_monthList" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                  </Col>
                  <Col span="3">
                    <Select v-model="birthday_end_days" style="width:100%" clearable placeholder='日' @on-change="changeBirthdayEndDay">
                      <Option v-for="item in birthday_end_daysList" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                  </Col>
                </Row>
              </Form-item>
            </Col>
            <Col :span="5">
              <Form-item label="介绍人：" prop="jsr_id">
                <Select
                  clearable
                  filterable
                  v-model="searchData.jsr_id"
                  placeholder="请选择"
                >
                  <Option :value="v.id" v-for="v in userList" :key="v.id">
                    {{ v.name + ' - ' + v.branch_name }}
                  </Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col :lg="{span: 10, offset: 10}" :md="{span: 10, offset: 10}"><Button type="warning" @click.native="handleSearch">&nbsp;&nbsp;搜索&nbsp;&nbsp;</Button>
            &nbsp;&nbsp;<Button type="warning" @click.native="resetQuery">&nbsp;&nbsp;重置&nbsp;&nbsp;</Button></Col>
          </Row>
        </Form>
      </div>
    </div>
    <div class="content-box">
      <h2 class="content-title border-bottom">
        <span>会员列表</span>
        <div class="member-operate">
                    <template v-if="power.indexOf('服务评价人') > -1">
						<Button type="success" ghost size="small" @click="handleModify(0)">修改所属门店</Button>
						<Button type="success" ghost size="small" @click="handleModify(1)">修改服务员工</Button>
						<Button type="success" ghost size="small" @click="handleModify(2)">修改顾客类型</Button>
					</template>
					<template v-if="power.indexOf('会员管理--创建新档案') > -1">
						<Button type="primary" size="small" :to="{name: 'newMember'}">新建档案</Button>
					</template>
          <Button type="info" size="small" @click="exportData" v-show="power.indexOf('数据统计--数据导出权限') > -1">导出</Button>
        </div>
      </h2>
      <div class="content-body">
        <Table :columns="columns" :data="memberData" ref="table" @on-selection-change="handleSelect"></Table>
        <Row>
          <Col span="12">
            <div class="table-oprate">
              <Button @click="handleAllRemove" size="small" v-show="power.indexOf('会员管理--删除会员信息权限') > -1">删除</Button>
            </div>
          </Col>
          <Col span="12">
            <span class="records">共{{ pageTotal }}条记录</span>
          </Col>
        </Row>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="pageTotal"
              :current="searchData.page"
              :page-size="searchData.size"
              @on-change="handleChangePage"
            ></Page>
          </div>
        </div>
      </div>
    </div>
		<Modal
      v-model="showModal"
      title="批量修改"
      class-name="vertical-center-modal"
      width="1000"
      footer-hide
			@on-visible-change="handleVisible"
    >
      <div class="member-btn">
				<Form inline :label-width="55" label-position="left">
					<template v-if="modifyType === 0">
						<Form-item label="省份：">
							<Select clearable v-model="modifyData.pid" placeholder="请选择" @on-change="changeProModify" style="width: 200px">
								<Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
							</Select>
						</Form-item>
						<Form-item label="城市：">
							<Select clearable v-model="modifyData.cid" placeholder="请选择" @on-change="changeCityModify" style="width: 200px">
								<Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
							</Select>
						</Form-item>
						<Form-item label="门店：">
							<Select clearable v-model="modifyData.sid" placeholder="请选择" @on-change="changeBranchModify" style="width: 200px">
								<Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
							</Select>
						</Form-item>
					</template>
					<template v-else-if="modifyType === 1">
						<Form-item label="服务员工：" :label-width="85">
							<Select filterable v-model="modifyData.staff" placeholder="请选择" @on-change="changeStaff" style="width: 200px">
								<Option :value="v.id" v-for="v in staffList" :key="v.id">{{v.name}}</Option>
							</Select>
						</Form-item>
					</template>
					<template v-else-if="modifyType === 2">
						<Form-item label="顾客类型：" :label-width="85">
							<Select clearable v-model="modifyData.type1" placeholder="请选择" @on-change="changeTypeModify" style="width: 200px">
								<Option :value="v.id" v-for="v in typeList" :key="v.id">{{v.name}}</Option>
							</Select>
							&nbsp;
							<Select clearable multiple v-model="modifyData.type2" placeholder="请选择" @on-change="changeTypeItemModify" style="width: 200px">
								<Option :value="v.id" v-for="v in typeItemList" :key="v.id">{{v.name}}</Option>
							</Select>
						</Form-item>
					</template>
					<Form-item :label-width="0">
						人数：{{ modifyUsers.length }}
					</Form-item>
					<Form-item :label-width="5">
						<Button type="primary" @click="modifyMember">确认修改</Button>
					</Form-item>
				</Form>
				<Table :columns="modifyColumns" :data="modifyUsers"></Table>
      </div>
    </Modal>
  </div>
</template>
<script type="text/ecmascript-6">
import memberService from '@/services/memberService';
import City from '@/mixins/City';
import defaultAvatar from '@/assets/img/bg_avatar.png';
import familyService from '@/services/familyService';

export default {
	data() {
		const ageSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchData.b_age !== '') {
					this.$refs.formValidate.validateField('b_age');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchData.b_age !== '') {
						this.$refs.formValidate.validateField('b_age');
					}
					callback();
				}
			}
		};
		const ageLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchData.s_age !== '' &&
						Number(this.searchData.b_age) < Number(this.searchData.s_age)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		return {
			searchData: {
				name: '',
				gender: '',
				staff: '',
				nation: '',
				marita: '',
				native_place: '',
				phone: '',
				start: '',
				end: '',
				grade: '',
				conhabit: '',
				id_card: '',
				density_grade: '',
				density_detail: '',
				member_type_id: '',
				s_age: '',
				b_age: '',
				job: '',
				address: '',
				pid: '',
				cid: '',
				sid: '',
				member_type1: '',
				member_type2: [],
				branch: [],
				page: 1,
				size: 10,
				device_type: '',
				ABO_type: '',
				birthdaystart: '',
				birthdayend: '',
				jsr_id: '',
			},
			userList: [],
			type_item_ids: [],
			typeList: [],
			typeItemList: [],
			typeItem: {},
			proList: [],
			cityList: [],
			city: {},
			store: {},
			storeList: [],
			new_staff_list: {},
			staffFormData: [],
			nationList: [],
			maritalType: [],
			jobType: [],
			gradeType: [],
			nativePlaceList: [],
			native_place: [],
			nativeData: [],
			densityGradeList: [],
			density_grade: [],
			densityData: [],
			branchData: [],
			branch: [],
			member_type_Array: [],
			time: [],
			gender: '',
			conhabit: '',
			ABO_type: '',
			birthday_start_monthList: [],
			birthday_start_daysList: [],
			birthday_start_month: null,
			birthday_start_days: null,
			birthday_end_monthList: [],
			birthday_end_daysList: [],
			birthday_end_month: null,
			birthday_end_days: null,
			columns: [
				{ type: 'selection', key: '_checked', width: 60, align: 'center' },
				{ title: '姓名', key: 'name', align: 'center' },
				{
					title: '头像',
					key: 'photo',
					align: 'center',
					render(h, params) {
						if (!this.memberData) {
							if (params.row.photo) {
								return h('div', [
									h('img', {
										class: 'img-avatar',
										attrs: {
											src: params.row.photo,
										},
									}),
								]);
							}
							return h('div', [
								h('img', {
									class: 'img-avatar',
									attrs: {
										src: defaultAvatar,
									},
								}),
							]);
						}
					},
				},
				{ title: '年龄', key: 'age', align: 'center' },
				{ title: '性别', key: 'sex', align: 'center' },
				{ title: '顾客类型', key: 'new_member_type', align: 'center' },
				{ title: '联系电话', key: 'phone', align: 'center' },
				{ title: '所属分院', key: 'branch', align: 'center' },
				{ title: '建档时间', key: 'create_time', align: 'center' },
				{ title: '服务员工', key: 'staff', align: 'center' },
				{ title: '家庭关系', key: 'jtgx', align: 'center' },
				{ title: '介绍人', key: 'jsr', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
							return h('div', [
								h('a', {
									attrs: {
										href: `#/base/member/detail/${this.memberData[params.index].id}`,
										target: '_blank',
									},
									class: 'icon_eye',
								}),
								h('a', {
									class: 'icon_del',
									on: {
										click: () => {
											this.$Modal.confirm({
												title: '提醒',
												content: `确定删除 [ ${params.row.name} ] 吗`,
												onOk: () => {
													this.handleSingleRemove(params.index);
												},
											});
										},
									},
								}),
							]);
						}
						return h('div', [
							h('a', {
								class: 'icon_eye',
								attrs: {
									href: `#/base/member/detail/${this.memberData[params.index].id}`,
									target: '_blank',
								},
								style: {
									marginRight: 0,
								},
							}),
						]);
					},
				},
			],
			memberData: [],
			checkedUsers: [],
			pageTotal: 0,
			ruleValidate: {
				s_age: [{ validator: ageSmall, trigger: 'blur' }],
				b_age: [{ validator: ageLarge, trigger: 'blur' }],
			},
			branchShow: false,
			branch_id: [],
			power: [],
			choConhabit: false,
			proName: '',
			cityName: '',
			sName: '',
			deviceList: [
				{
					value: '0',
					label: '不匹配',
				},
				{
					value: '1',
					label: '有手机',
				},
				{
					value: '2',
					label: '无手机',
				},
			],
			ABOList: [
				{
					value: 'O',
					label: 'O',
				},
				{
					value: 'A1',
					label: 'A1',
				},
				{
					value: 'A2',
					label: 'A2',
				},
				{
					value: 'B1',
					label: 'B1',
				},
				{
					value: 'B2',
					label: 'B2',
				},
				{
					value: 'A1B1',
					label: 'A1B1',
				},
				{
					value: 'A2B1',
					label: 'A2B1',
				},
				{
					value: 'A1B2',
					label: 'A1B2',
				},
				{
					value: 'A2B2',
					label: 'A2B2',
				},
			],
			modifyType: 0,
			modifyText: ['所属门店', '服务员工', '顾客类型'],
			staffList: [],
			showModal: false,
			modifyUsers: [],
			newText: '',
			modifyData: {
				pid: '',
				cid: '',
				sid: '',
				staff: '',
				type1: '',
				type2: [],
			},
			modifyColumns: [
				{ title: '姓名', key: 'name', align: 'center' },
				{ title: '年龄', key: 'age', align: 'center' },
				{ title: '性别', key: 'sex', align: 'center' },
				{
					align: 'center',
					renderHeader: (h) => {
						return h('span', null, `原${this.modifyText[this.modifyType]}`);
					},
					render: (h, params) => {
						const fields = ['branch', 'staff', 'new_member_type'];
						return h('span', null, params.row[fields[this.modifyType]]);
					},
				},
				{
					align: 'center',
					renderHeader: (h) => {
						return h('span', null, `新${this.modifyText[this.modifyType]}`);
					},
					render: (h) => {
						return h('span', null, this.newText || '未选择');
					},
				},
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						return h(
							'span',
							{
								on: {
									click: () => {
										this.handleDelete(params.index);
									},
								},
								style: { color: '#ed4014', cursor: 'pointer' },
							},
							'删除',
						);
					},
				},
			],
		};
	},
	created() {
		// 初始化月份
		for (let i = 1; i < 13; i++) {
			this.birthday_start_monthList.push(i);
		}
		// 初始化天数
		for (let d = 1; d < 32; d++) {
			this.birthday_start_daysList.push(d);
		}
		for (let i = 1; i < 13; i++) {
			this.birthday_end_monthList.push(i);
		}
		// 初始化天数
		for (let d = 1; d < 32; d++) {
			this.birthday_end_daysList.push(d);
		}
        this.getUsers();
	},
	watch: {
		// 如果 branchArr 发生改变，这个函数就会运行
		member_type_Array() {
			this.searchData.member_type_id = this.member_type_Array[1];
		},
	},
	methods: {
        getUsers() {
			familyService.getAllUsers().then((data) => {
				this.userList = data.list;
			});
		},
		resetQuery() {
			this.searchData = {
				name: '',
				gender: '',
				staff: '',
				nation: '',
				marita: '',
				native_place: '',
				phone: '',
				start: '',
				end: '',
				grade: '',
				conhabit: '',
				id_card: '',
				density_grade: '',
				density_detail: '',
				member_type_id: '',
				s_age: '',
				b_age: '',
				job: '',
				address: '',
				pid: '',
				cid: '',
				sid: '',
				member_type1: '',
				member_type2: [],
				branch: [],
				page: 1,
				size: 10,
				device_type: '',
				ABO_type: '',
				birthdaystart: '',
				birthdayend: '',
				jsr_id: '',
			}
		},
		// 顾客类型
		changeType() {
			this.typeItemList = this.typeItem[this.searchData.member_type1];
			this.type_item_ids = [];
		},
		changeTypeItem(value) {
			this.searchData.member_type2 = JSON.stringify(value);
		},
		changeItems() {
			if (!this.searchData.member_type1) {
				this.$Message.warning('请先选择顾客类型');
			}
		},
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择员工
		changeBranch() {
			this.staffFormData = this.new_staff_list[this.searchData.sid];
			this.searchData.staff = '';
		},
		changeDevice(value) {
			this.searchData.device_type = value;
		},
		changeABO(value) {
			this.searchData.ABO_type = value;
		},
		initData() {
			const list = JSON.parse(localStorage.getItem('userInfo'));
			memberService.getBase().then((data) => {
				this.nationList = data.nation;
				this.maritalType = data.marital;
				this.jobType = data.p_job;
				data.grade.forEach((item) => {
					this.gradeType.unshift(item);
				});
				this.densityGradeList = data.density;
				this.branchData = data.branch;
				this.proList = data.new_staff_list.pro;
				this.city = data.new_staff_list.city;
				this.store = data.new_staff_list.store;
				this.new_staff_list = data.new_staff_list.staff;
				this.typeList = data.member_type.list_type1;
				this.typeItem = data.member_type.list_type2;
				this.power = list.user_info.power;
				this.memberTypeData = data.member_type;
				if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
					this.branchShow = false;
				} else {
					if (
						this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
						this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
					) {
						this.branchShow = true;
						this.branch_id = list.user_info.branch;
						// this.staffValue = this.branch_id[2]
						this.searchData.sid = this.branch_id[2];
						this.searchData.cid = this.branch_id[1];
						this.searchData.pid = this.branch_id[0];
						this.proName = list.user_info.pro_name;
						this.cityName = list.user_info.city_name;
						this.sName = list.user_info.branch_name;
						this.changeBranch();
					}
					this.getQuery();
				}
			});
		},
		// 会员信息列表
		getQuery() {
			this.searchData.gender = this.gender;
			this.searchData.conhabit = this.conhabit;
			this.nativePlaceList = City;
			memberService.getMemberQuery(this.searchData).then((data) => {
				this.memberData = data.list;
				this.pageTotal = data.row_size;
				this.checkedUsers = [];
			});
		},
		// 搜索会员信息
		handleSearch() {
			this.searchData.page = 1;
			if (this.conhabit === '0') {
				this.searchData.density_grade = '';
				this.searchData.density_detail = '';
			}
			this.getQuery();
			this.searchData.member_type_id = this.searchData.member_type_id || '';
			this.searchData.end = this.searchData.end || '';
			this.searchData.density_grade = this.searchData.density_grade || '';
			this.searchData.density_detail = this.searchData.density_detail || '';
		},
		// 删除一个会员
		handleSingleRemove(index) {
			const req = [this.memberData[index].id];
			memberService.deleteMember({ member_id: JSON.stringify(req) }).then(() => {
				this.getQuery();
				this.$Message.warning('删除成功');
			});
		},
		// 删除多个会员
		handleAllRemove() {
			if (this.checkedUsers.length === 0) {
				this.$Message.warning('请先选择要删除的数据');
			} else {
				this.$Modal.confirm({
					title: '提醒',
					content: '确定删除所选会员数据',
					onOk: () => {
						this.handleConfirmDelete();
					},
				});
			}
		},
		// 确认删除
		handleConfirmDelete() {
			memberService
				.deleteMember({
					member_id: JSON.stringify(this.checkedUsers.map((u) => u.member_id)),
				})
				.then(() => {
					this.getQuery();
					this.checkedUsers = [];
					this.$Message.warning('删除成功');
				});
		},
		// 导出会员信息列表
		exportData() {
			location.href = `http://conlife.sskh.net:9928/api/member/export?name=${this.searchData.name}&gender=${this.searchData.gender}&nation=${this.searchData.nation}&marita=${this.searchData.marita}&native_place=${this.searchData.native_place}&phone=${this.searchData.phone}&id_card=${this.searchData.id_card}&job=${this.searchData.job}&grade=${this.searchData.grade}&conhabit=${this.searchData.conhabit}&density_grade=${this.searchData.density_grade}&density_detail=${this.searchData.density_detail}&s_age=${this.searchData.s_age}&b_age=${this.searchData.b_age}&member_type1=${this.searchData.member_type1}&member_type2=${this.searchData.member_type2}&start=${this.searchData.start}&end=${this.searchData.end}&birthdaystart=${this.searchData.birthdaystart}&birthdayend=${this.searchData.birthdayend}&pid=${this.searchData.pid}&cid=${this.searchData.cid}&sid=${this.searchData.sid}&staff=${this.searchData.staff}`;
		},
		getStaffs() {
			memberService.getServiceStaffList().then((data) => {
				this.staffList = data;
			});
		},
		handleModify(type) {
			if (this.checkedUsers.length === 0) {
				this.$Message.warning('请先选择要修改的数据');
				return;
			}
			this.getStaffs();
			this.modifyUsers = this.checkedUsers;
			this.modifyType = type;
			this.showModal = true;
		},
		async modifyMember() {
			if (this.modifyUsers.length === 0) {
				this.$Message.warning('暂无需要修改的数据');
				return;
			}
			const ids = this.modifyUsers.map((v) => v.id).join(',');
			if (this.modifyType === 0) {
				await memberService.modifyStoreBatch({
					member_id: ids,
					branch_id: this.modifyData.sid,
				});
			} else if (this.modifyType === 1) {
				await memberService.modifyStaffBatch({
					member_id: ids,
					staff_id: this.modifyData.staff,
				});
			} else if (this.modifyType === 2) {
				await memberService.modifyTypeBatch({
					member_id: ids,
					member_type1_id: this.modifyData.type1,
					member_type2_id: this.modifyData.type2.join(','),
				});
			}
			this.showModal = false;
			this.$Message.success('修改成功');
			this.getQuery();
		},
		changeProModify(val) {
			this.cityList = this.city[val];
			this.modifyData.cid = '';
		},
		changeCityModify(val) {
			this.storeList = this.store[val];
			this.modifyData.sid = '';
		},
		changeBranchModify(val) {
			this.newText = this.storeList?.find((v) => v.id === val)?.name;
		},
		changeStaff(val) {
			this.newText = this.staffList?.find((v) => v.id === val)?.name;
		},
		changeTypeModify(val) {
			this.typeItemList = this.typeItem[val];
			this.modifyData.type2 = [];
		},
		changeTypeItemModify(val) {
			this.newText = this.typeItemList?.find((v) => v.id === val[0])?.name;
		},
		handleDelete(val) {
			this.$Modal.confirm({
				title: '提醒',
				content: '确定删除所选会员数据',
				onOk: () => {
					this.modifyUsers.splice(val, 1);
				},
			});
		},
		handleVisible(val) {
			if (!val) {
				this.newText = ''
			}
		},
		// 建档开始结束时间
		handleChangeDate(date) {
			this.searchData.start = date[0];
			this.searchData.end = date[1];
		},
		// 生日开始结束时间
		handleChangeBirthday(date) {
			this.searchData.birthdaystart = date[0];
			this.searchData.birthdayend = date[1];
		},
		// 密度改变
		handleChangeDensity(value, selectedData) {
			this.density_grade = selectedData.map((o) => o.label);
			this.searchData.density_grade = value[0];
			this.searchData.density_detail = value[1];
		},
		// 性别改变
		handleGender(value) {
			this.searchData.gender = value;
		},
		// 是否与子女同住
		handleConhabit(value) {
			if (value === '1') {
				this.choConhabit = true;
			} else {
				this.choConhabit = false;
				this.densityData = [];
			}
			this.searchData.conhabit = value;
		},
		handleSelect(selection) {
			this.checkedUsers = selection;
		},
		// 页码
		handleChangePage(page) {
			this.searchData.page = page;
			this.getQuery();
		},
		// 点击服务员工先选择门店
		handleChangeStaff() {
			if (!this.searchData.sid) {
				this.$Message.warning('请先选择门店');
			}
		},
		// 点击门店先选择城市
		changeBossBranch() {
			if (!this.searchData.cid) {
				this.$Message.warning('请先选择城市');
			}
		},
		// 点击城市先选择省份
		changeBossCity() {
			if (!this.searchData.pid) {
				this.$Message.warning('请先选择省份');
			}
		},
		handleNative(value, selectedData) {
			// this.nativeData = selectedData.map(o => o.label).join(',')
			this.searchData.native_place = selectedData.map((o) => o.value).join(',');
		},
		handleMarita(value) {
			this.searchData.marita = value;
		},
		getBirthdayStartCountDays(year, month) {
			const thisDate = new Date(year, month, 0);
			const number = thisDate.getDate();
			this.birthday_start_days = 1;
			this.birthday_start_daysList = [];
			for (let d = 1; d <= number; d++) {
				this.birthday_start_daysList.push(d);
			}
		},
		changeBirthdayStartMonth(value) {
			this.birthday_start_month = value;
			this.getBirthdayStartCountDays(new Date().getFullYear(), value);
			this.searchData.birthdaystart = `${this.birthday_start_month}-${this.birthday_start_days}`;
		},
		getBirthdayEndCountDays(year, month) {
			const thisDate = new Date(year, month, 0);
			const number = thisDate.getDate();
			this.birthday_end_days = 1;
			this.birthday_end_daysList = [];
			for (let d = 1; d <= number; d++) {
				this.birthday_end_daysList.push(d);
			}
		},
		changeBirthdayEndMonth(value) {
			this.birthday_end_month = value;
			this.getBirthdayEndCountDays(new Date().getFullYear(), value);
			this.searchData.birthdayend = `${this.birthday_end_month}-${this.birthday_end_days}`;
		},
		changeBirthdayStartDay(value) {
			this.searchData.birthdaystart = `${this.birthday_start_month}-${value}`;
		},
		changeBirthdayEndDay(value) {
			this.searchData.birthdayend = `${this.birthday_end_month}-${value}`;
		},
	},
	mounted() {
		this.initData();
		this.getQuery();
	},
};
</script>
<style lang="css" scoped>
.member-manager .member-search {
  background-color: #fff;
  margin-bottom: 20px;
}
.member-manager .ivu-icon {
  float: left;
  line-height: 35px;
}
.search-form {
  padding: 20px 15px;
}
.search-form .ivu-input-number {
  width: 100%;
}
.member-operate {
  display: flex;
	gap: 10px;
}
</style>
